<template>
  <div>
    <page-title :heading="$t('erp.lang_nav_waregroups') "
                :permissionAdd="this.$store.getters['permissions/checkPermission']('waregroups_create') && this.hybrid != 'true'"
                show-add-button :subheading="$t('erp.lang_nav_waregroups')" :icon="icon"></page-title>
    <div class="app-main__inner">
      <Itemgroups></Itemgroups>
    </div>
  </div>
</template>

<script>
import PageTitle from "../../../Layout/Components/PageTitle.vue";
import Itemgroups from "../../../components/erp/baseData/Itemgroups.vue";

export default {
  components: {
    PageTitle,
    Itemgroups
  },
  data: () => ({
    icon: 'pe-7s-folder icon-gradient bg-tempting-azure',
    hybrid: process.env.VUE_APP_HYBRID,
  })
}
</script>